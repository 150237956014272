<template>
  <v-container >

    <v-row>
      <v-col cols="12" align="center">

        <v-card v-if="!isLoading" class="mt-4" max-width="1000px">
          <v-card-title class="pb-0">

            <v-container>

              <v-row>
                <v-col class="mb-2 py-0">
                  <v-avatar color="orange" size="38" class="mb-2">
                    <v-icon dark>mdi-draw</v-icon>
                  </v-avatar>
                  <p class="text-h5 mb-0">
                    Inscripciones del curso {{course.name}}
                  </p>
                </v-col>
              </v-row>

            </v-container>
            
          </v-card-title>

          <v-card-text>
            <v-container>

              <v-divider class="my-3"></v-divider>

              <!-- Registrations -->
              <v-row>
                <v-col>

                  <v-data-table
                    :headers="headers"
                    :items="course.students"
                    dense
                    disable-pagination
                    hide-default-footer
                  />

                </v-col>
              </v-row>

            </v-container>

          </v-card-text>

        </v-card>

        <v-container v-else>
          <v-row>
            <v-col>
              <LoadingIndicator />
            </v-col>
          </v-row>
        </v-container>

      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import LoadingIndicator from '@/components/LoadingIndicator.vue'

export default {
  name: `ViewPublicCourseRegistration`,
  components: {
    LoadingIndicator,
  },
  data: () => ({
    //state
    isLoading: true,
    //data
    course: {
      _id: null,
      name: null,
      professorEmail: null,
      professorName: null,
      slots: null,
      students: [],
    },
    headers: [
      { text: 'Rut', value: 'rut' },
      { text: 'Nombre Alumno', value: 'fullname' },
      { text: 'Carrera', value: 'career' },
      { text: 'Email', value: 'emailUCN' },
      { text: 'Telefono', value: 'phone' },
    ],
  }),
  methods: {
    async loadLastRegistration() {
      try {
      
        this.isLoading = true
      
        const resp = await this.axios.get(`${process.env.VUE_APP_APIURL}/registration/studentlist/${this.$route.params.id}`)

        if(!resp.data.data) {
          this.isLoading = false
          return
        }

        this.course = resp.data.data
      
      } catch (e) {
        console.log(e)
      } finally {
        this.isLoading = false
      }
    },
  },
  mounted() {
    this.loadLastRegistration()
  },
}
</script>